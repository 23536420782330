import React from 'react';
import AboutCard from '../AboutCard';
const styles = {
  headingStyle: {
    marginTop: '5%',
    zIndex: 2,
    marginBottom: '5%',
    color: '#3D3D3D',
    textAlign: 'center',
    fontSize: 35,
    filter: 'drop-shadow(.5px .5px .5px)',
  },
  aboutStyle: {
    minHeight: '85vh',
    margin: 'auto',
    padding: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: '9vh',
    marginBottom: '10vh',
    borderRadius: '20%',
  },
  textStyle: {
    width: '90%',
    fontSize: 25,
    marginTop: '2%',
  },
  imgStyle: {
    filter: 'drop-shadow(.5px .5px .5px)',
    width: '40%',
    marginBottom: '2%',
  },
  cardStyle: {
    background: 'white',
    borderRadius: '5%',
    minWidth: '40%',
    maxWidth: '550px',
    zIndex: 2,
    margin: '1%',
    marginTop: '1%',
    marginBottom: '1%',
    padding: '2%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageStyle: {
    width: '100%',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  mapStyle: {
    maxWidth: '90%',
  },
  ulStyle: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
function About() {
  return (
    <div className="aboutMe" style={styles.aboutStyle}>
      <h1 style={styles.headingStyle}>About</h1>
      {/* Wedding */}
      <div style={styles.cardStyle} class="contact">
        <img style={styles.imageStyle} key="1" src="./weddingSpot.jpg" />
        <h1 style={styles.headingStyle}>The Wedding</h1>
        <p>Our wedding will take place at 3:00 PM on September 16th 2023!</p>
        <p>
          The ceremony will be small and simple with a small number of close
          friends and relatives as guests.
        </p>
        <p>
          Dress semi-formal to casual based on your comfort, prepare for summer
          weather it will be an outdoor wedding.
        </p>
        <p>
          To keep in theme with our venue we will have picnic foods and games
          after the ceremony.
        </p>
      </div>
      {/* Venue */}
      <div style={styles.cardStyle} class="contact">
        <img style={styles.imageStyle} key="2" src="./hoytArboretum.jpg" />
        <h1 style={styles.headingStyle}>The Venue</h1>
        <p>
          Our wedding will be taking place at the Hoyt Arboretum in Southwest
          Portland right near the rose test garden and oregon zoo!
        </p>
        <p>
          The Ceremony will take place by the stephens pavilion picnic shelter.
          The address is:
        </p>
        <a href="https://goo.gl/maps/KvnGZbs8CEbWGWxGA">
          3119 SW Fairview Blvd, Portland, OR 97205
        </a>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.723628376916!2d-122.71938032504988!3d45.51564183000687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950987ed73ecd7%3A0x702d54ec93ac2a5d!2sStevens%20Pavilion%20Picnic%20Shelter!5e0!3m2!1sen!2sus!4v1685418096624!5m2!1sen!2sus"
          width="600"
          height="450"
          style={styles.mapStyle}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <p>
          Parking is available in the lot across from the picnic shelter Sadly
          parking is not reserved and is paid but is usually plentiful
        </p>
        <p>
          The area is a beautiful place to take walks or hikes so feel free to
          wear good shoes and explore before or after the ceremony!
        </p>
        <p>For more information about the Arboretum and wedding venue:</p>
        <a href="https://www.hoytarboretum.org/visit/rentals/stevens-pavilion/">
          https://www.hoytarboretum.org/visit/rentals/stevens-pavilion/
        </a>
      </div>
      {/* Area */}
      <div style={styles.cardStyle} class="contact">
        <img style={styles.imageStyle} key="3" src="./portlandCity.jpg" />
        <h1 style={styles.headingStyle}>The Area</h1>
        <p>The portland area is home to lots to do and see while you visit.</p>
        <p>Here are some places we like around the city!</p>
        <h5 style={styles.headingStyle}>Breakfast and Coffee</h5>
        <ul style={styles.ulStyle}>
          <li>
            <p>Flying Cat Coffee</p>
            <a href="https://order.online/store/flying-cat-coffee-portland-266016/?hideModal=true&pickup=true">
              order.online/flyingcatcoffee
            </a>
          </li>
          <li>
            <p>Fried Egg I'm in Love</p>
            <a href="https://www.friedegglove.com/">friedegglove.com</a>
          </li>
          <li>
            <p>Seven Virtues Coffee</p>
            <a href="https://www.sevenvirtuespdx.com/">sevenvirtuespdx.com</a>
          </li>
        </ul>
        <h5 style={styles.headingStyle}>Lunch and Dinner</h5>
        <ul style={styles.ulStyle}>
          <li>
            <p>Virtuous Pie</p>
            <a href="https://virtuouspie.com/locations/portland/hosford-abernethy/">
              virtuouspie.com
            </a>
          </li>
          <li>
            <p>Fire On the Mountain</p>
            <a href="https://www.portlandwings.com/">portlandwings.com</a>
          </li>
          <li>
            <p>Jojos Pdx</p>
            <a href="https://jojopdx.com/">jojopdx.com</a>
          </li>
          <li>
            <p>
              Food carts! There are tons of great options but I'll link the
              Asylum which is one of the larger options.
            </p>
            <a href="http://www.pdxasylum.com/">pdxasylum.com</a>
          </li>
        </ul>
        <h5 style={styles.headingStyle}>Sights and Sounds</h5>
        <ul style={styles.ulStyle}>
          <li>
            <p>Portland Rose Garden</p>
            <a href="https://www.travelportland.com/attractions/portland-rose-garden/">
              Travelportland.com/Internationaltestgarden
            </a>
          </li>
          <li>
            <p>Multnomah Falls</p>
            <a href="https://www.fs.usda.gov/recarea/crgnsa/recarea/?recid=30026">
              usda.gov/multnomahfalls
            </a>
          </li>
          <li>
            <p>Peninsula Park</p>
            <a href="https://www.portland.gov/parks/peninsula-park">
              portland.gov/peninsula-park
            </a>
          </li>
          <li>
            <p>Forest Park</p>
            <a href="https://www.portland.gov/parks/forest-park">
              portland.gov/forestpark
            </a>
          </li>
        </ul>
        <h5 style={styles.headingStyle}>Shopping</h5>
        <ul style={styles.ulStyle}>
          <li>
            <p>Powells Books</p>
            <a href="https://www.powells.com/">powells.com</a>
          </li>
          <li>
            <p>Curiosities in Tigard (Our hometown)</p>
            <a href="https://www.instagram.com/curiositiesvintage/?hl=en">
              @curiositiesvintage on instagram
            </a>
          </li>
          <li>
            <p>House of Vintage</p>
            <a href="https://www.houseofvintagenw.com/portland/">
              houseofvintagenw.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default About;
