import React from 'react';
const styles = {
  cardStyle: {
    background: 'white',
    borderRadius: '5%',
    minWidth: '40%',
    maxWidth: '550px',
    zIndex: 2,
    margin: '1%',
    marginTop: '1%',
    marginBottom: '1%',
    padding: '2%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageStyle: {
    width: '100%',
  },
  linkStyle: {
    textDecoration: 'none',
  },
};

function Card(props) {
  console.log(props);
  return (
    <div style={styles.cardStyle} class="contact">
      <img style={styles.imageStyle} key={props.key} src={props.src} />
      <h1 style={styles.headingStyle}>{props.heading}</h1>
      {props.content}
    </div>
  );
}

export default Card;
