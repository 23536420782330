import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Nav from './Nav';
import Footer from './Footer';
import About from './pages/About';
import Home from './pages/Home';
import Gallery from './pages/Gallery';
function Container() {
  return (
    <>
      <Router>
        <Nav />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/About" element={<About />} />
        </Routes>
        {<Footer />}
      </Router>
    </>
  );
}

export default Container;
