import React from 'react';

const styles = {
  headingStyle: {
    marginTop: '5%',
    zIndex: 2,
    marginBottom: '5%',
    //  color: '#3D3D3D',
    textAlign: 'center',
    fontSize: 35,
  },
  aboutStyle: {
    backgroundColor: 'rgba(255, 255, 255)',
    minHeight: '85vh',
    width: '80%',
    margin: 'auto',
    padding: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: '9vh',
    marginBottom: '10vh',
    borderRadius: '20%',
  },
  textStyle: {
    fontSize: 20,
    marginTop: '2%',
  },
  imgStyle: {
    filter: 'drop-shadow(.5px .5px .5px)',
    width: '40%',
    marginBottom: '2%',
  },
};

function AboutMe() {
  return (
    <div className="aboutMe">
      <div className="card" id="homeCard" style={styles.aboutStyle}>
        <h2 id="aboutMe" style={styles.headingStyle}>
          Kris and Emmy
        </h2>
        <img
          style={styles.imgStyle}
          src="./engagementShoot1/IMG_2848.jpg"
          alt="Kris and Emmy in front of the Stephen's Pavilion."
          id="portrait"
        />
        <p style={styles.textStyle}>Welcome to our wedding information site!</p>
        <p style={styles.textStyle}>
          We are so happy to have you join us for our special day.
        </p>
        <p style={styles.textStyle}>
          Check out the about tab for more information about our wedding, venue,
          and things to do in portland!
        </p>
        <p style={styles.textStyle}>
          Keep an eye on the gallery tab for images of the wedding.
        </p>
        <p style={styles.textStyle}>-Kris, and Emmy</p>
      </div>
    </div>
  );
}

export default AboutMe;
