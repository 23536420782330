import React, { lazy, Suspense } from 'react';
import Card from '../ImageCard.js';
// import Images from '../Images';
const Images1 = React.lazy(() => import('../Images1'));
const Images2 = React.lazy(() => import('../Images2'));
const styles = {
  portfolioStyle: {
    minHeight: '80vh',
    marginTop: '15vh',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headingStyle: {
    flexBasis: '100%',
    zIndex: 2,
    textAlign: 'center',
    marginTop: '5%',
    marginBottom: '2%',
    color: '#3D3D3D',
    textAlign: 'center',
    fontSize: 35,
    filter: 'drop-shadow(.5px .5px .5px)',
  },
  comingSoonStyle: {
    width: '50%',
    height: '20vh',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    borderRadius: '50px',
    background: 'white',
  },
};
function Portfolio() {
  return (
    <div style={styles.portfolioStyle}>
      <h1 style={styles.headingStyle}>Gallery</h1>
      <Suspense fallback={<img src="./Spinner.svg"></img>}>
        <h1 style={styles.headingStyle}>Engagement shoot one</h1>
        <Images1 />
        <h1 style={styles.headingStyle}>Engagement shoot two</h1>
        <Images2 />
      </Suspense>
    </div>
  );
}

export default Portfolio;
