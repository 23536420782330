import React from 'react';

const styles = {
  footerStyle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '10vh',
    bottom: '0',
    background: '#849F62',
    alignItems: 'center',
  },
  navStyle: {
    width: '20%',
    marginLeft: 'auto',
    marginRight: '10%',
  },
  listStyle: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    listStyleType: 'none',
  },
  textStyle: {
    marginLeft: '2%',
    fontSize: 10,
    color: '#FFFFFF',
  },
};

function Footer() {
  return (
    <div style={styles.footerStyle} className="footer">
      <h5 style={styles.textStyle}>Created by Kris Terran Baily 2023</h5>
    </div>
  );
}

export default Footer;
