import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  liStyle: {
    borderRadius: '5px',
    padding: '2%',
    display: 'flex',
    Width: '20vw',
    Height: '9vh',
    justifyContent: 'center',
    allignItems: 'center',
    listStyleType: 'none',
    // margin: '5%',
    textAlign: 'center',
    whiteSpace: 'noWrap',
    boxShadow: '2px 2px 10px #96C1AE',
  },
  linkStyle: {
    justifyContent: 'center',
    allignItems: 'center',
    Width: '16vw',
    Height: '9vh',
    color: 'white',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgStyle: {
    marginTop: '3px',
    textAlign: 'center',
    maxHeight: '12vh',
    zIndex: '2',
  },
  headerStyle: {
    position: 'relative',
    height: '13.2vh',
    background: '#849F62',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'spaceBetween',
  },
  textStyle: {
    textAlign: 'center',
    width: 'fitContent',
    height: '12vh',
    color: '#3D3D3D',
  },
  burgerStyle: {
    maxHeight: '12vh',
  },
};

function Nav() {
  const [showNav, setShowNav] = React.useState(false);

  const handleShowNav = () => {
    setShowNav(!showNav);
  };
  return (
    <nav style={styles.headerStyle} className="navbar">
      <div style={styles.navStyle} className="container">
        <Link style={styles.imgStyle} to="/">
          <img style={styles.imgStyle} src="./logo.png" alt="logo" />
        </Link>
        <div className="menu-icon" onClick={handleShowNav}>
          <img src="./burger.svg" style={styles.burgerStyle}></img>
        </div>
        <div className={`nav-elements  ${showNav && 'active'}`}>
          <ul>
            <li style={styles.liStyle} className="nav-item">
              <Link style={styles.linkStyle} to="/home">
                Home
              </Link>
            </li>
            <li style={styles.liStyle} className="nav-item">
              <Link style={styles.linkStyle} to="/about">
                About
              </Link>
            </li>
            <li style={styles.liStyle} className="nav-item">
              <Link style={styles.linkStyle} to="/gallery">
                Gallery
              </Link>
            </li>
            <li style={styles.liStyle} className="nav-item">
              <a
                style={styles.linkStyle}
                href="https://registry.theknot.com/kris-baily-emmy-st-dennis-september-2023-or/62563728"
              >
                Registry
              </a>
            </li>
          </ul>
        </div>
        <div
          class="custom-shape-divider-top-1685323273"
          style={{ marginTop: '13vh' }}
        >
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
      {/* 
      <ul className="nav nav-tabs" style={styles.ulStyle}>
        <li className="nav-item">
          <Link style={styles.linkStyle} to="/home">
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link style={styles.linkStyle} to="/about">
            About
          </Link>
        </li>
        <li className="nav-item">
          <Link style={styles.linkStyle} to="/gallery">
            Gallery
          </Link>
        </li>
      </ul> */}
    </nav>
  );
}

export default Nav;
