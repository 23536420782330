//Import our reactApp
import React from 'react';
//import our header, Nav and footer
// Import our container which will have our scripts deciding which components to render
import Container from './components/Container';

// Render our page
const App = () => <Container />;

// Export!
export default App;
